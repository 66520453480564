import React from "react";
import Helmet from 'react-helmet'
const defaultState = {
    isMenuOpen: false,
    toggleMenu: () => {},
};

const AppContext = React.createContext(defaultState);

class AppProvider extends React.Component {
    state = {
        isMenuOpen: false,
    };

    toggleMenu = () => {
        let isMenuOpen = !this.state.isMenuOpen;
        localStorage.setItem("isMenuOpen", JSON.stringify(isMenuOpen));
        this.setState({ isMenuOpen });
    };

    componentDidMount() {
        // Getting dark mode value from localStorage!
        const isMenuOpen = JSON.parse(localStorage.getItem("isMenuOpen"));
        if (isMenuOpen) {
            this.setState({ isMenuOpen: isMenuOpen });
        }
    }

    render() {
        const { children } = this.props;
        const { isMenuOpen } = this.state;
        return (
            <AppContext.Provider
                value={{
                    isMenuOpen,
                    toggleMenu: this.toggleMenu,
                }}
            >
                <Helmet htmlAttributes={{ lang: 'ja' }} />
                {children}
            </AppContext.Provider>
        );
    }
}

export default AppContext;

export { AppProvider };
