import "normalize.css";
import "./src/styles/app.scss";

// export const shouldUpdateScroll = ({ routerProps: { location } }) => {
//     if (location.hash === "") {
//         window.scrollTo(0, 0);
//     }

//     return false;
// };

import React from "react";
import { AppProvider } from "./src/context/AppContext";

export const onInitialClientRender = () => {
    if (typeof window !== `undefined`) {
        window.scrollTo(0, 0);
        if (typeof window.history !== `undefined`) {
            window.history.scrollRestoration = "manual";
        }
    }
};
// export const shouldUpdateScroll = (args) => {
//     if (typeof window !== `undefined`) {
//         window.scrollTo(0, 0);
//         if (typeof window.history !== `undefined`) {
//             window.history.scrollRestoration = "manual";
//         }
//     }
//     return false;
// };
// export const onRouteUpdate = () => {
//     console.log("onRouteUpdate");
//     if (typeof window !== `undefined`) {
//         console.log("onRouteUpdate inside");
//         window.scrollTo(0, 0);
//     }
// };

export const wrapRootElement = ({ element }) => (
    <AppProvider>{element}</AppProvider>
);
